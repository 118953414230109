
.home-gallery {
  width: 100%;
  display: flex;
  grid-gap: 20px;
  align-items: stretch;
}

.home-container2 {
  width: 25%;
  align-self: stretch;
}
.home-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}

.home-container3 {
  width: 25%;
  display: flex;
  grid-gap: 20px;
  align-items: stretch;
  flex-direction: column;
}

.home-image2 {
  width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}

.home-image3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}

.home-container4 {
  width: 50%;
  display: flex;
  grid-gap: 20px;
  flex-direction: column;
}

.home-image4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}

.home-container5 {
  width: 100%;
  display: flex;
  grid-gap: 20px;
}

.home-image5 {
  flex: 1;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}

.home-image6 {
  flex: 1;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}


.home-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.home-navbar {
  align-items: center;
  flex-direction: column;
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 22px;
  object-fit: cover;
  margin-right: 14px;
}
.home-text101 {
  color: var(--dl-color-scheme-orange100);
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.home-links1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-burger-menu {
  display: none;
}

.home-icon10 {
  width: 22px;
  height: 22px;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image2 {
  width: 30%;
  object-fit: contain;

}

.home-text108 {
  color: var(--dl-color-scheme-orange100);
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-links2 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-text110 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text111 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text112 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text113 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text114 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon16 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon18 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-max-width2 {
  align-items: center;
}
.home-content1 {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-subtitle {
  color: var(--dl-color-scheme-orange100);
  margin-bottom: var(--dl-space-space-unit);
}
.home-title1 {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text116 {
  color: var(--dl-color-scheme-lightgreen);
}
.home-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-image3 {
  position: relative;
  padding-bottom: var(--dl-space-space-eightunits);
}
.home-hero-image1 {
  flex: 1;
  max-width: 100%;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: auto;
}
.home-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
.home-image4 {
  right: -170px;
  bottom: 0px;
  position: absolute;
  object-fit: contain;
}
.home-max-width3 {
  align-items: stretch;
}
.home-hero-image2 {
  object-fit: contain;
  border-radius: 48px;
}
.home-content2 {
  width: 50%;
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text117 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text118 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text120 {
  color: var(--dl-color-scheme-orange100);
}
.home-text122 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-section2 {
  background-color: rgba(255, 199, 139, 0.14);
}
.home-max-width4 {
  align-items: stretch;
}
.home-content3 {
  width: 50%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text123 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text124 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text125 {
  color: var(--dl-color-scheme-orange100);
  font-family: Urbanist;
}
.home-text127 {
  font-family: Urbanist;
}
.home-text128 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-hero-image3{
  object-fit: contain;
  margin-right: -250px;
  border-radius: 48px;
}
.home-section3 {
  background-color: var(--dl-color-scheme-green100);
}
.home-max-width5 {
  align-items: stretch;
}
.home-image7 {
  width: 50%;
}
.home-hero-image4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 48px;
}
.home-content4 {
  width: 40%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text129 {
  color: var(--dl-color-scheme-white);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text130 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-step1 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-orange100);
}
.home-text131 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container14 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title2 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text132 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
.home-step2 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-orange100);
}
.home-text133 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container15 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title3 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text134 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
.home-step3 {
  display: flex;
  flex-direction: row;
}
.home-number3 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-orange100);
}
.home-text135 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container16 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title4 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text136 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
.home-max-width6 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text137 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text138 {
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-section5 {
  padding-top: 0px;
}
.home-max-width7 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-faq {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-eightunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-questions {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text141 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text142 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text143 {
  font-family: Urbanist;
}
.home-text145 {
  color: var(--dl-color-scheme-orange100);
  font-family: Urbanist;
}
.home-trigger1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-text146 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.home-icon20 {
  width: 12px;
  height: 12px;
}
.home-text147 {
  margin-top: 8px;
}
.home-trigger2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-text148 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.home-icon22 {
  width: 12px;
  height: 12px;
}
.home-text149 {
  margin-top: 8px;
}
.home-trigger3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-text150 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.home-icon24 {
  width: 12px;
  height: 12px;
}
.home-text151 {
  margin-top: 8px;
}
.home-trigger4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-text152 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.home-icon26 {
  width: 12px;
  height: 12px;
}
.home-text153 {
  margin-top: 8px;
}
.home-image8 {
  width: 50%;
  object-fit: cover;
  margin-right: -200px;
}
.home-banner {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: 48px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/group%2011-1200w.png");
}
.home-text154 {
  color: var(--dl-color-scheme-lightgreen);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text155 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: "Urbanist";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text159 {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-btns {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-button18 {
  font-size: 16px;
  padding-top: 12px;
  margin-right: var(--dl-space-space-unit);
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
}
.home-button19 {
  padding-top: 12px;
  margin-right: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-links-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container17 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-text163 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text164 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text165 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text166 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text167 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text169 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text170 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text171 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text172 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text173 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text175 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text176 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text177 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text178 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text179 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text180 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text182 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text183 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text184 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text185 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text188 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text191 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text192 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text193 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text194 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text196 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text197 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text198 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text199 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text200 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text201 {
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .home-content1 {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .home-image3 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image1 {
    margin-bottom: 0px;
  }
  .home-max-width3 {
    flex-direction: column;
  }
  .home-image5 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image2 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .home-content2 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-max-width4 {
    flex-direction: column;
  }
  .home-content3 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image6 {
    padding-bottom: 0px;
  }
  .home-hero-image3 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .home-max-width5 {
    flex-direction: column;
  }
  .home-image7 {
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image4 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .home-content4 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-max-width6 {
    flex-direction: column;
  }
  .home-max-width7 {
    flex-direction: column;
  }
  .home-questions {
    width: 60%;
  }
  .home-image8 {
    margin-right: -100px;
  }
  .home-footer {
    flex-direction: column;
  }
  .home-links-container {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    flex-direction: row;
  }
  .home-container17 {
    flex-wrap: wrap;
    margin-right: 0px;
  }
}
@media(max-width:479px){
 .clothGrid{
    display: grid;
    grid-template-columns: 90%;
    row-gap:70px;
    column-gap: 30px;
    justify-content: center;
    text-align: center; 
    word-break: keep-all;
    margin-top: 15px;
    align-items: center;
    margin-bottom:70px;
  }
}
@media(min-width:801px){
  .clothGrid{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    row-gap:70px;
    column-gap: 30px;
    justify-content: center;
    text-align: center; 
    word-break: keep-all;
    margin-top: 15px;
    align-items: center;
    margin-bottom:70px;
  }
}
@media(min-width:480px) and (max-width:800px){
  .clothGrid{
    display: grid;
    grid-template-columns: 48% 48% ;
    row-gap:70px;
    column-gap: 30px;
    justify-content: center;
    text-align: center; 
    word-break: keep-all;
    margin-top: 15px;
    align-items: center;
    margin-bottom:70px;
  }
}
@media(max-width: 767px) {
  .home-desktop-menu {
    display: none;
  }
  .home-links1 {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-text110 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text111 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text112 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text113 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text114 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-max-width2 {
    flex-direction: column-reverse;
  }
  .home-content1 {
    width: 100%;
    margin-right: 0px;
  }
  .home-hero-image1 {
    margin-right: 0px;
  }
  .home-content2 {
    width: 100%;
    margin-right: 0px;
  }
  .home-content3 {
    width: 100%;
    margin-right: 0px;
  }
  .home-content4 {
    width: 100%;
    margin-right: 0px;
  }
  .home-cards-container {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-faq {
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-questions {
    width: 100%;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image8 {
    width: 100%;
    margin-right: 0px;
  }
  .home-banner {
    padding: var(--dl-space-space-twounits);
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-links-container {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-container17 {
    width: 100%;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .home-mobile-menu {
    padding: 16px;
  }

  .button-secondary-mobile{
    font-size: 3vw;
    padding: 2vw 5vw;
    width: 100px;
    word-break: keep-all;
  }
  .home-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
  }
  .home-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-container17 {
    align-items: center;
    margin-right: 0px;
    justify-content: space-between;
  }
}

.not-found-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-container2 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.not-found-text2 {
  color: rgb(38, 38, 38);
  font-size: 252px;
  margin-top: -20px;
  font-weight: 900;
  margin-bottom: -20px;
  letter-spacing: -20px;
}
.not-found-container3 {
  width: 421px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-text3 {
  text-align: center;
  font-weight: 400;
}
@media (min-width: 0px) and (max-width: 500px) {
  .not-found-text2 {
    color: rgb(38, 38, 38);
    font-size: 150px;
    margin-top: -20px;
    font-weight: 900;
    margin-bottom: -20px;
    letter-spacing: -12px;
  }
  .not-found-text3 {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    width: 90%;
    margin: auto;
  }
    
    
  } 